<template>
  <div>
    <px-fornecedor-ofertas-header @change="onChangeSearchText"
                                  ref="filterHeader"
                                  :initial-search-text="searchTextParam"/>
    <v-row no-gutters>
      <v-col cols="12" sm="2" md="3" class="mt-6">
        <px-fornecedor-ofertas-filtros :fornecedor-id="fornecedorId"
                                       :filters="filters"
                                       @change="onAddFilter"
                                       @remove="onRemoveFilter"/>
      </v-col>
      <v-col cols="12" sm="10" md="9">
        <px-fornecedor-ofertas-empty-result v-if="!loading && !ofertas.content.length"
                                            :search-text="searchText"
                                            @clear="onClearFilter"/>
        <px-loading-horizontal-cards :loading="loading"/>
        <px-loading-horizontal-cards :loading="loading"/>
        <v-row v-if="!loading" class="mt-5">
          <template v-for="item in ofertas.content">
            <v-col cols="12" sm="6" md="4"
                   :key="item.id"
                   class="d-flex align-center justify-center"
                   align="center">
              <px-oferta-card :show-price="isLogged" :show-fornecedor="false" :oferta="item"/>
            </v-col>
          </template>
        </v-row>
        <div class="text-center mt-10">
          <v-pagination
            v-model="page"
            :length="pagesLimit"
            :total-visible="5"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import { setQueryParam } from '@/services/route-oferta-service';
import OfertaListMixin from '@/mixins/oferta-list-mixin';
import { mapGetters } from 'vuex';
import OfertaService from '../ofertas/oferta-service';
import FornecedorOfertasHeader from './FornecedorOfertasHeader.vue';
import FornecedorOfertasEmptyResult from './FornecedorOfertasEmptyResult.vue';
import FornecedorOfertasFiltros from './FornecedorOfertasFiltros.vue';

const LIMIT = 12;
const PAGE = 1;

export default {
  mixins: [paginationMixin, OfertaListMixin],
  props: {
    fornecedorId: [String, Number],
  },
  data() {
    return {
      pageParam: this.$route.query.page,
      searchTextParam: this.$route.query.s,
      loading: false,
      searchText: null,
      ofertas: {
        content: [],
        total: 0,
      },
      page: PAGE,
      pagesLimit: 1,
      listOptions: {
        page: PAGE,
        limit: LIMIT,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      optionsList: {
        page: PAGE,
        itemsPerPage: LIMIT,
        sortBy: '',
        sortDesc: false,
        multiSort: false,
      },
    };
  },
  computed: {
    queryFilter() {
      return this.$route.query.q;
    },
    ...mapGetters(['isLogged']),
  },
  methods: {
    buildFilter() {
      let filter = `fornecedor.id = ${this.fornecedorId}`;
      if (this.queryFilterString) {
        filter = `${filter} and ${this.queryFilterString}`;
      }
      return `${filter} order by disponivel desc`;
    },
    buildParams() {
      const params = {
        limit: this.listOptions.limit,
        offset: this.listOptions.offset,
        query: this.buildFilter(),
      };
      if (this.searchText) {
        params.q = this.searchText;
        params.relevancia = true;
      }
      return params;
    },
    getOfertas() {
      this.loading = true;
      OfertaService.queryOfertas(this.buildParams())
        .then(({ data }) => {
          this.ofertas = data;
          this.setPagesLimit();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetPage() {
      this.page = PAGE;
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    setPagesLimit() {
      this.pagesLimit = Math.ceil(this.ofertas.total / LIMIT);
    },
    onChangeOptions() {
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getOfertas();
    },
    setupPage() {
      const pageNumber = Number(this.pageParam || PAGE);
      this.page = pageNumber;
      this.optionsList.page = pageNumber;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
    },
    setupSearch() {
      if (this.searchTextParam) {
        this.searchText = this.searchTextParam;
      }
    },
    setPageParam(newPage) {
      setQueryParam('page', newPage);
    },
    onChangeSearchText(searchText) {
      this.searchText = searchText;
      setQueryParam('s', this.searchText);
      this.resetPage();
      this.getOfertas();
    },
    onClearFilter() {
      this.$refs.filterHeader.clearText();
    },
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.optionsList.page = newValue;
      this.onChangeOptions();
      this.setPageParam(newValue);
    },
    queryFilterString(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.resetPage();
      this.getOfertas();
    },
  },
  created() {
    this.setupQueryFilter();
    this.setupPage();
    this.setupSearch();
    this.getOfertas();
  },
  components: {
    pxFornecedorOfertasHeader: FornecedorOfertasHeader,
    pxFornecedorOfertasEmptyResult: FornecedorOfertasEmptyResult,
    pxFornecedorOfertasFiltros: FornecedorOfertasFiltros,
  },
};
</script>
