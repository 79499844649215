<template>
  <div>
    <px-filtro-selecao title="Categoria"
                       :options="options"
                       :loading="loading"
                       @change="onSelectFilter"/>
  </div>
</template>

<script>
import { remove, isEmpty, sortBy } from 'lodash';
import OfertaService from '@/modules/ofertas/oferta-service';
import FiltroSelecao from '@/modules/ofertas/filters/FiltroSelecao.vue';

export default {
  props: {
    query: String,
    fornecedorId: {
      type: [String, Number],
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      options: [],
      selected: [],
    };
  },
  methods: {
    onSelectFilter(event) {
      if (event.enabled) {
        this.selected.push(event);
      } else {
        remove(this.selected, item => item.id === event.id);
      }
      this.$emit('change', this.selected);
    },
    isCategoriaEnabled(categoria) {
      if (!this.filters || !this.filters.categoria || !this.filters.categoria.value) {
        return false;
      }
      return this.filters.categoria.value.includes(categoria.id);
    },
    buildOptionItem(categoria) {
      return {
        id: categoria.id,
        description: categoria.descricao,
        enabled: this.isCategoriaEnabled(categoria),
      };
    },
    buildQuery() {
      return `fornecedor.id = ${this.fornecedorId}`;
    },
    getCategorias() {
      this.loading = true;
      OfertaService.getCategoriasFilter({
        query: this.buildQuery(),
      })
        .then(({ data }) => {
          this.options = sortBy(data.map(this.buildOptionItem), 'description');
          const initialSelection = this.options.filter(item => item.enabled);
          if (!isEmpty(initialSelection)) {
            this.selected = initialSelection;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCategorias();
  },
  components: {
    pxFiltroSelecao: FiltroSelecao,
  },
};
</script>
