<template>
  <div>
    <px-fornecedor-oferta-filtro-categoria :fornecedor-id="fornecedorId"
                                           :filters="filters"
                                           @change="onChangeCategoriaFilter"/>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import FornecedorOfertaFiltroCategoria from './FornecedorOfertaFiltroCategoria.vue';

export default {
  props: {
    fornecedorId: {
      type: [String, Number],
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onChangeCategoriaFilter(selected) {
      const items = selected.map(item => item.id);
      const event = {
        property: 'categoria',
        value: items,
      };
      if (isEmpty(items)) {
        this.$emit('remove', event);
        return;
      }

      this.$emit('change', event);
    },
  },
  components: {
    pxFornecedorOfertaFiltroCategoria: FornecedorOfertaFiltroCategoria,
  },
};
</script>
