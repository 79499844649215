<template>
  <v-container class="mkt-content-container mt-5">
    <div v-if="fornecedor">
      <px-fornecedor-header v-if="fornecedor.pessoa.capa" :fornecedor="fornecedor"/>
      <px-fornecedor-header-sem-capa v-else :fornecedor="fornecedor"/>
    </div>
    <v-row class="mt-5">
      <v-col class="headline font-weight-bold">
        Todas as ofertas
      </v-col>
    </v-row>
    <px-fornecedor-ofertas class="mt-3"
                           :fornecedor-id="fornecedorId"/>
  </v-container>
</template>

<script>
import FornecedorService from '@/api/fornecedor-marketplace-service';
import FileUrlPathService from '@/api/file-url-path-service';
import EventService from '@/events';
import axios from 'axios';
import { EventTypes } from 'px-business-components';
import FornecedorHeader from '@/modules/fornecedor/FornecedorHeader.vue';
import FornecedorHeaderSemCapa from '@/modules/fornecedor/FornecedorHeaderSemCapa.vue';
import FornecedorOfertas from './FornecedorOfertas.vue';

export default {
  data() {
    return {
      fornecedorId: this.$route.params.id,
      fornecedor: null,
      loading: false,
    };
  },
  computed: {
    fotoPath() {
      if (!this.fornecedor.pessoa.logo) {
        return null;
      }
      return FileUrlPathService.getPath(this.fornecedor.pessoa.logo.path);
    },
  },
  methods: {
    getFornecedor() {
      this.loading = true;
      FornecedorService.getVisualizacao(this.fornecedorId)
        .then(({ data }) => {
          this.fornecedor = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fireEvent() {
      EventService.createAcessoTarget(axios, EventTypes.Resource.FORNECEDOR,
        { id: this.fornecedorId }, this.fornecedorId);
    },
  },
  created() {
    this.getFornecedor();
    this.fireEvent();
  },
  components: {
    pxFornecedorOfertas: FornecedorOfertas,
    pxFornecedorHeader: FornecedorHeader,
    pxFornecedorHeaderSemCapa: FornecedorHeaderSemCapa,
  },
};
</script>
